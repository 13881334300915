
import {computed, defineComponent, onMounted, onUpdated, ref,} from "vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import { dateConvert } from "@/core/filters/datatime";
import AppCore from "@/core/appcore/AppCore";
import {Turkish} from "flatpickr/dist/l10n/tr";
import flatpickr from "flatpickr";

interface newItemData {
  itemLockStatus: string;
}

export default defineComponent({
  name: "operation-transfer-company-update",
  components: {
  },


  props: {
    resID: String,
  },




  setup(props, ctx) {

    onMounted(()=> {
      changeCurSymbol();
    });

    onUpdated(() => {
      changeCurSymbol();
    });


    const newItemData = ref<newItemData>({
      itemLockStatus: "",
    });


    store.dispatch(Actions.ACCOUNT_LIST_ALL, {});

    store.dispatch(Actions.COMPANY_DEPARTMENTS_LIST, {});

    store.dispatch(Actions.RESERVATIONS_ALL_DETAILS, {'ID': props.resID});

    let isEmit = true;

    function changeCurSymbol() {
      const myPriceCur = myDetails.value.SaleCur;
      const priceParamsCur = document.getElementsByClassName("spanPriceParamsCur")
      for (let i=0; i<priceParamsCur.length; i++){
        let eachCur = priceParamsCur[i] as HTMLElement;
        if(myPriceCur==="TRY"){
          eachCur.innerText= "₺";
        }
        if(myPriceCur==="STG"){
          eachCur.innerText= "£";
        }
        if(myPriceCur==="EUR"){
          eachCur.innerText= "£";
        }
      }
    }

    const myDetails = computed(() => {
      let dataComputed =store.getters.reservationsDetails;
      detailTaken(dataComputed);
      return dataComputed;
    })

    const detailTaken = (prmDetail) => {
      if (prmDetail.ID!==undefined) {
        if (isEmit) {
          //isEmit = false;
          ctx.emit('detailChanged', prmDetail);
        }
      }
    }


    function changeLockStatus(prmResID){
     const ccvElemnt = document.getElementById("itemLockStatus_"+prmResID) as HTMLInputElement;
     if(myDetails.value.LockStatus=='OPEN'){
       ccvElemnt.checked;
     }


      console.log("changeLockStatus Lock Type" + newItemData.value.itemLockStatus)


     /* Swal.fire({
        title: 'Emin misin.?',
        text: "Bu Otelin Konfirme statüsü 'Onaylı' olarak değiştirilecektir.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Evet, Onayla!',
        cancelButtonText: `Vazgeç`,
      }).then(function (result) {
        if (result.isConfirmed) {
          store.dispatch(Actions.RESERVATIONS_EDIT, {ID: props.resID, LockStatus: newItemData.value.itemLockStatus, })
              .then(() => {
                Swal.fire('Onaylandı!', 'Kayıt onaylama işlemi tamamlandı.', 'success').then((result)=> {
                  location.reload();
                });
              }).catch(() => {
            Swal.fire({text: store.getters.getPriceListsErrors[0], confirmButtonText: "Tekrar dene!",
              icon: "error", buttonsStyling: false, customClass: {confirmButton: "btn fw-bold btn-light-danger",},
            });
          });
        }
      });*/

    }


    const submit = () => {

      const payload1 = {
        ID: props.resID,
        AccountID: myDetails.value.AccountID,
      }

      store.dispatch(Actions.RESERVATIONS_EDIT, payload1)
          .then(() => {
            isEmit = true;
            store.dispatch(Actions.RESERVATIONS_ALL_DETAILS, {'ID': props.resID});
            Swal.fire({
              text: "Tebrikler. Başarılı bir şekilde işlemi tamamladınız.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Geri Dön!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              // Go to page after successfully login
              //router.push({name: "userlist"});
            });
          }).catch(() => {
        Swal.fire({
          text: store.getters.getReservationsErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Tekrar dene!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });
    }

    return {
      libTools, dateConvert,
      submit,
      myDetails,
      newItemData,
      changeLockStatus,

    };
  },
});
