import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ps-5" }
const _hoisted_2 = { class: "text-muted fw-bold text-muted d-block fs-7" }
const _hoisted_3 = ["id"]
const _hoisted_4 = ["id"]
const _hoisted_5 = ["id"]
const _hoisted_6 = { class: " " }
const _hoisted_7 = { class: "badge badge-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tr", null, [
    _createElementVNode("td", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.dayindex) + ". Gün", 1)
    ]),
    _createElementVNode("td", null, [
      _createElementVNode("input", {
        type: "text",
        class: "form-control form-control-sm form-control-solid fs-7",
        id: `${'txt_RoomDetail_DayDetail_Price_'+_ctx.idCode+'_'+_ctx.roomid+'_'+_ctx.dayindex}`
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("td", null, [
      _createElementVNode("input", {
        type: "text",
        class: "form-control form-control-sm form-control-solid fs-7",
        id: `${'txt_RoomDetail_DayDetail_AgencyPrice_'+_ctx.idCode+'_'+_ctx.roomid+'_'+_ctx.dayindex}`
      }, null, 8, _hoisted_4)
    ]),
    _createElementVNode("td", null, [
      _createElementVNode("input", {
        type: "text",
        class: "form-control form-control-sm form-control-solid fs-7",
        id: `${'txt_RoomDetail_DayDetail_Cost_'+_ctx.idCode+'_'+_ctx.roomid+'_'+_ctx.dayindex}`
      }, null, 8, _hoisted_5)
    ]),
    _createElementVNode("td", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.arrDayCampaign, (eachCamp, inxCamp) => {
        return (_openBlock(), _createElementBlock("div", {
          key: inxCamp,
          class: "d-flex flex-column mb-2"
        }, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, _toDisplayString(eachCamp), 1)
          ])
        ]))
      }), 128))
    ])
  ]))
}