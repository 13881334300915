
import {defineComponent, ref} from "vue";
import RoomDetail from "@/components/general-modals/cards/RoomDetail2.vue";
import HotelRoomConcept from "@/components/general-modals/cards/HotelRoomConcept.vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {dateConvert} from "@/core/filters/datatime";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
    name: "reservation-hotel-update-modal",
    components: {
        HotelRoomConcept,
        RoomDetail,
    },
    props: {
        reservationid: {type:Number, required:true},
    },
    methods: {
        initContent(prmReservation) {
            console.log("ReservationHotelUpdateModal initContent START");
            (this.refComp_RoomDetail as any).initContent(prmReservation);
        },
        setContent(prmHotelInfo) {
            //console.log("ReservationHotelUpdateModal setContent prmHotelInfo: "+JSON.stringify(prmHotelInfo));
            (this.refComp_RoomDetail as any).setContent(prmHotelInfo);
            (this.refComp_HotelRoomConcept as any).setContent(prmHotelInfo);
          this.getProductInner(prmHotelInfo);
        },
        getRezervation(prmReservation){
          this.getRezervationInner(prmReservation);
        },

    },
    setup(props) {
        let refComp_RoomDetail = ref(null);
        let refComp_HotelRoomConcept = ref(null);
        const arrReservationInfo = ref([]);
        const arrProductInfo = ref({});

      function getRezervationInner(prmReservation) {

        (arrReservationInfo.value).push(prmReservation);
      }

      function getProductInner(prmProduct) {

        (arrProductInfo.value)=(prmProduct);
      }

        function click_Update() {
            processSend();
        }

        function processSend() {
            console.log("processSend ResHotelUpdate START ");

            let rv = {isok : true, result: {hotel: {}, room:{} }};

            //HotelRoomConcept
            if (rv.isok){
                let resHotelRoomConcept  = (refComp_HotelRoomConcept.value as any).getResults();
                if (resHotelRoomConcept.isok) {
                    rv.result.hotel = resHotelRoomConcept.result;
                } else {
                    rv.isok = false;
                }
            }

            //RoomDetail
            if (rv.isok) {
                let resA = (refComp_RoomDetail.value as any).getResults();
                if (resA.isok) {
                    rv.result.room = resA.room;
                } else {
                    rv.isok = false;
                }
            }

            console.log("processSend "+JSON.stringify(rv));
            console.log("ReservationProductId: "+(rv.result.room as any).roomdetail.ReservationProductId);

            if (rv.isok) {
                const vvPayload = {
                    ProductType: "HOTEL",
                    ID: (rv.result.room as any).roomdetail.ReservationProductId,
                    ReservationID: props.reservationid,
                    SecretProfit: (rv.result.room as any).roomdetail.SecretProfit,
                    RemarksINT4: (rv.result.room as any).roomdetail.RemarksINT4,
                    BankCommissionPercentPrice: (rv.result.room as any).roomdetail.BankCommissionPercentPrice,
                    SubAgencyPercentPrice: (rv.result.room as any).roomdetail.SubAgencyPercentPrice,
                    AgencyPercentPrice: (rv.result.room as any).roomdetail.AgencyPercentPrice,
                    AgencyPercent: (rv.result.room as any).roomdetail.AgencyPercent,
                    SubAgencyPercent: (rv.result.room as any).roomdetail.SubAgencyPercent,
                    BankCommissionPercent: (rv.result.room as any).roomdetail.BankCommissionPercent,
                    StartDate: dateConvert.method.convertToDateFormated((rv.result.room as any).roomdetail.StartDate, "YYYY-MM-DD"),
                    FinishDate: dateConvert.method.convertToDateFormated((rv.result.room as any).roomdetail.FinishDate, "YYYY-MM-DD"),
                    ADTCount: (rv.result.room as any).roomdetail.PaxCountADT,
                    CHDCount: (rv.result.room as any).roomdetail.PaxCountCHD,

                    TotalCost: (rv.result.room as any).daytotals.Cost,
                    TotalCurCost: (rv.result.room as any).daytotals.Cost,
                    TotalPrice: Number((rv.result.room as any).daytotals.AgencyPrice) + Number((rv.result.room as any).roomdetail.RemarksINT4) - Number((rv.result.room as any).roomdetail.BankCommissionPercentPrice) - Number((rv.result.room as any).roomdetail.SubAgencyPercentPrice) - Number((rv.result.room as any).roomdetail.AgencyPercentPrice),
                    TotalAccountPrice: (rv.result.room as any).daytotals.AgencyPrice,

                    RemarksJSON1: JSON.stringify((rv.result.room as any).paxes),
                    RemarksJSON2: JSON.stringify( (rv.result.room as any).days),
                    RemarksINT2: (rv.result.hotel as any).HotelRoomId,
                    RemarksVC3: (rv.result.hotel as any).HotelRoomTitle,
                    RemarksINT3: (rv.result.hotel as any).HotelConceptId,
                    RemarksVC4: (rv.result.hotel as any).HotelConceptTitle,
                }
                console.log("processSend vvPayload: "+JSON.stringify(vvPayload));

                store.dispatch(Actions.RESERVATIONS_PRODUCT_UPDATE, vvPayload).then((prmData) => {
                    //console.log(prmData);
                    if (prmData.status===200) {
                        Swal.fire('Güncellendi!', 'Kayit günceleme işlemi tamamlandı.', 'success').then((result)=>{
                            location.reload();
                        });
                    } else {
                        libTools.method.swalError("Tamam", "Hata Oluştu");
                    }
                }).catch(() => {
                    libTools.method.swalError("Tamam", "Hata Oluştu");
                });

            }
        }

        return {
            refComp_RoomDetail,
            refComp_HotelRoomConcept,
            click_Update,
            getRezervationInner,
          getProductInner,
          arrReservationInfo,
          arrProductInfo,
        };
    }


});

