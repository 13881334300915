
import {computed, defineComponent, onMounted, ref} from "vue";
import ReservationDetailsUpdate from "@/components/reservations/ReservationDetailsUpdate.vue";
import ReservationDetailsUpdateModal  from "@/components/payment-methods/bank-accounts/ReservationDetailsUpdateModal.vue";
import ReservationDetailsContactModal from "@/components/general-modals/ReservationDetailsContactModal.vue";
import NewReservationFlight from "@/components/reservations/NewReservationFlight.vue";
import UpdateReservationFlight from "@/components/reservations/UpdateReservationFlight.vue";
import ReservationHotelAddModel from "@/components/general-modals/ReservationHotelAddModel.vue";
import ReservationHotelUpdateModal from "@/components/general-modals/ReservationHotelUpdateModal.vue";
import ReservationProductLogMainsModal from "@/components/general-modals/ReservationProductLogMainsModal.vue";
import NewReservationModal from "@/components/general-modals/NewReservationModal.vue";
import ReservationTransferAddModal from "@/components/general-modals/ReservationTransferAddModal.vue";
import ReservationTransferUpdateModal from "@/components/general-modals/ReservationTransferUpdateModal.vue";
import ReservationPaymentAddModal from "@/components/general-modals/ReservationPaymentAddModal.vue";
import HotelVoucherModal from "@/components/general-modals/vouchers/HotelVoucherModal2.vue";
import FlightVoucherModal from "@/components/general-modals/vouchers/FlightVoucherModal2.vue";
import ReservationVoucherModal from "@/components/general-modals/vouchers/ReservationVoucherModal.vue";
import TransferVoucherModal from "@/components/general-modals/vouchers/TransferVoucherModal.vue";
import KPlusDetailModal from "@/components/general-modals/KPlusDetailModal.vue";
import Remarks from "@/components/general-modals/cards/Remarks.vue";
import {useRoute} from "vue-router";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import { dateConvert } from "@/core/filters/datatime";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import MasterConfig from "@/core/config/MasterConfig";
import Swal from "sweetalert2/dist/sweetalert2.js";
import reservationConfirmTypes from "@/core/data/reservationConfirmType";
import PrintInvoice from "@/components/invoices/invoices/PrintInvoice.vue"
import paymentMethods from "@/core/data/paymentMethods";


export default defineComponent({
  name: "Reservation-Details",
  components: {
    ReservationDetailsUpdate,
    ReservationDetailsUpdateModal,
    ReservationDetailsContactModal,
    NewReservationFlight,
    UpdateReservationFlight,
    ReservationHotelAddModel,
    ReservationHotelUpdateModal,
    NewReservationModal,
    ReservationTransferAddModal,
    ReservationTransferUpdateModal,
    HotelVoucherModal,
    FlightVoucherModal,
    ReservationVoucherModal,
    TransferVoucherModal,
    ReservationPaymentAddModal,
    KPlusDetailModal,
    Remarks,
    ReservationProductLogMainsModal,
    PrintInvoice,

  },
  setup() {

    onMounted(()=>{
      console.log("Reservation Details Page is Opened");

    });


    var theDetail = ref({});
    const arrPaxes = ref([]);

    const refHotelVoucherModal = ref(null);
    const refFlightVoucherModal = ref(null);
    const refHotelAddModal = ref(null);
    const refHotelUpdateModal = ref(null);
    const refHotelHistoryModal = ref(null);
    const refFlightUpdateModal = ref(null);
    const refTransferUpdateModal = ref(null);
    const refTransferVoucherModal = ref(null);
    const refReservationVoucherModal = ref(null);
    const refPaymentAddModal = ref(null);
    const refKPlusDetailModal = ref(null);
    const refProductLogMains = ref(null);

    const refRemarks = ref(null);

    const route = useRoute();
    const paramId = ref(route.params.id);

    const id = ref(route.params.id);
    const payload = {
      ID: id
    }


    store.dispatch(Actions.RESERVATIONS_ALL_DETAILS, {ID: paramId.value,}).then(() => {
      setTimeout( setInvoiceDetails, 500) ;
    })
  //  store.dispatch(Actions.RESERVATIONS_ALL_DETAILS, {ID: paramId.value,});
    const dataReservationsDetails = computed(() => {
      let dataComputed = store.getters.reservationsDetails;
      setDetail(dataComputed);
     // setTimeout(hideAddProductModalButton, 5000);
      return dataComputed;
    });


    function setDetail(prmDetail) {

      theDetail.value = prmDetail;
      arrPaxes.value = libTools.method.getPaxes(theDetail.value, "FLIGHT");
      if (arrPaxes.value.length===0) {
        let tmpPaxes = libTools.method.getPaxes(theDetail.value, "HOTEL");
        if (tmpPaxes.length>0) {
          arrPaxes.value = libTools.method.convertPaxesToGeneral( tmpPaxes[0] );
        }
      }
      //console.log("ReservationDetail setDetail theDetail: "+JSON.stringify(theDetail.value));
      //console.log("ReservationDetail setDetail arrPaxes: "+JSON.stringify(arrPaxes.value));
      //console.log("ReservationDetail setDetail PAYMENT: "+JSON.stringify((theDetail.value as any).PAYMENT));

      if (refRemarks.value!==null) {
        (refHotelUpdateModal.value as any).initContent(theDetail);
        (refRemarks.value as any).initComp(theDetail);
      }


    }

    function getItems(prmReservationDetail, prmProductType) {
      let rv = [];
      if (prmReservationDetail.ITEMS!==undefined) {
        for (let i=0; i<prmReservationDetail.ITEMS.length; i++) {
          let each = prmReservationDetail.ITEMS[i];
          if (each.ProductType===prmProductType) {
            rv.push(each);
          }
        }
      }
      return rv;
    }

    function getFirstPax(prmPaxes) {
      if (prmPaxes!==undefined && prmPaxes!==null) {
        if (prmPaxes.length>0) {
          return prmPaxes[0];
        }
      }
      return {
        PaxName: "",
        PaxSurname: "",
      }
    }

    function click_Open_PriceHistoryHotel() {
      (refHotelHistoryModal.value as any).setContent(theDetail);
    }

    function click_Open_VoucherReservation() {
      (refReservationVoucherModal.value as any).setValues(theDetail);
    }

    function click_Open_VoucherHotel(prmHotel) {
      console.log("click_Open_VoucherHotel arrPaxes: "+JSON.stringify(arrPaxes.value));

      (refHotelVoucherModal.value as any).setHotel(theDetail.value, prmHotel, arrPaxes.value);
    }

    function click_Open_VoucherFlight(prmFlight) {
      (refFlightVoucherModal.value as any).setFlight(theDetail, prmFlight, arrPaxes.value);
    }

    function click_Open_VoucherTransfer(prmTransfer) {
      (refTransferVoucherModal.value as any).setTransfer(theDetail, prmTransfer);

    }

    function click_Open_HotelAdd() {
      (refHotelAddModal.value as any).setContent(theDetail);
    }

    function click_Open_HotelUpdate(prmHotelInfo) {
      (refHotelUpdateModal.value as any).setContent(prmHotelInfo);
      (refHotelUpdateModal.value as any).getRezervation(theDetail.value);
    }

    function click_Open_FlightUpdate(prmFlightInfo) {
      (refFlightUpdateModal.value as any).setContent(prmFlightInfo);
    }

    function click_Open_TransferUpdate(prmTransferInfo) {
      (refTransferUpdateModal.value as any).setContent(prmTransferInfo);
      (refTransferUpdateModal.value as any).getRezervation(theDetail.value);
    }

    function click_Open_PaymentAdd() {
      (refPaymentAddModal.value as any).showComp(theDetail);
    }

    function click_Open_LogMains(prmProductInfo) {
      (refProductLogMains.value as any).setContent(prmProductInfo);
    }


    function click_Open_FlightDelete(prmFlightInfo) {
      console.log("click_Open_HotelDelete START");
      Swal.fire({
        title: 'Emin misiniz?', text: "Kaydi silmek işlemi geri alınamaz.", icon: "warning", showCancelButton: true, confirmButtonText: 'Kaydı Sil', cancelButtonText: `Vazgeç`, confirmButtonColor: '#9c1d1d', cancelButtonColor: '#3085d6',
      }).then(function (result) {
        if (result.isConfirmed) {
          store.dispatch(Actions.RESERVATIONS_PRODUCT_DELETE, {ID: prmFlightInfo.ID, ReservationID: prmFlightInfo.ReservationID, ProductType: "FLIGHT"})
              .then(() => {
                Swal.fire('Silindi!', 'Kayıt silme işlemi tamamlandı.', 'success').then((result)=> {
                  location.reload();
                });
              }).catch(() => {
            Swal.fire({text: store.getters.getPriceListsErrors[0], confirmButtonText: "Tekrar dene!",
              icon: "error", buttonsStyling: false, customClass: {confirmButton: "btn fw-bold btn-light-danger",},
            });
          });
        }
      });
    }


    function manuelProductCancel(prmProductID, prmReservationID){

      Swal.fire({
        title: 'Emin misiniz?', text: "Kayıdı iptal etmek üzeresiniz.", icon: "warning", showCancelButton: true, confirmButtonText: 'Kaydı İptal Et', cancelButtonText: `Vazgeç`, confirmButtonColor: '#9c1d1d', cancelButtonColor: '#3085d6',
      }).then(function (result) {
        if (result.isConfirmed) {

          const vvPayload = {
            ID: prmProductID,
            ReservationID: prmReservationID,
            SecretProfit: "0.00",
            AgencyPercentPrice: "0.00",
            BankCommissionPercentPrice: "0.00",
            SubAgencyPercentPrice: "0.00",
            AgencyPercent: "0.00",
            SubAgencyPercent: "0.00",
            BankCommissionPercent: "0.00",

          }
          store.dispatch(Actions.RESERVATIONS_PRODUCT_UPDATE, vvPayload).then((prmData) => {
            //console.log(prmData);

          });

          store.dispatch(Actions.EXPORT_TO_PDF_SET_EMAIL_STATUS, {Ids: [prmProductID], Action: "CANCEL CONFIRM", EmailKind: "CANCEL"})

         /* (document.getElementById("productdelete" + prmProductID) as HTMLElement
          ).style.setProperty("display", "none", "important");
          (document.getElementById("productupdate" + prmProductID) as HTMLElement
          ).style.setProperty("display", "none", "important");
          (document.getElementById("productcancel" + prmProductID) as HTMLElement
          ).style.setProperty("display", "none", "important");*/
              .then(() => {
                Swal.fire('İptal Edildi!', 'Kayıt iptal işlemi tamamlandı.', 'success').then(()=> {
                  location.reload();
                });
              }).catch(() => {
            Swal.fire({text: store.getters.getPriceListsErrors[0], confirmButtonText: "Tekrar dene!",
              icon: "error", buttonsStyling: false, customClass: {confirmButton: "btn fw-bold btn-light-danger",},
            });
          });
        }
      });

    }

    function manuelProductReCallCancel(prmProductID){

      Swal.fire({
        title: 'Emin misiniz?', text: "İptali geri almak üzeresiniz.", icon: "warning", showCancelButton: true, confirmButtonText: 'İptali Geri Al', cancelButtonText: `Vazgeç`, confirmButtonColor: '#9c1d1d', cancelButtonColor: '#3085d6',
      }).then(function (result) {
        if (result.isConfirmed) {


          store.dispatch(Actions.EXPORT_TO_PDF_SET_EMAIL_STATUS, {Ids: [prmProductID], Action: "NEW", EmailKind: "CONFIRM"})
              .then(() => {
                Swal.fire('Geri Alındı !', 'Kayıt iptal geri alma işlemi tamamlandı.', 'success').then(()=> {
                  location.reload();
                });
              }).catch(() => {
            Swal.fire({text: store.getters.getPriceListsErrors[0], confirmButtonText: "Tekrar dene!",
              icon: "error", buttonsStyling: false, customClass: {confirmButton: "btn fw-bold btn-light-danger",},
            });
          });
        }
      });

    }

    function manuelProductConfirm(prmProductID){

      Swal.fire({
        title: 'Emin misin.?',
        text: "Bu Otelin Konfirme statüsü 'Onaylı' olarak değiştirilecektir.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Evet, Onayla!',
        cancelButtonText: `Vazgeç`,
      }).then(function (result) {
        if (result.isConfirmed) {
          store.dispatch(Actions.EXPORT_TO_PDF_SET_EMAIL_STATUS, {Ids: [prmProductID], Action: "CONFIRM", EmailKind: "CONFIRM"})
              .then(() => {
                Swal.fire('Onaylandı!', 'Kayıt onaylama işlemi tamamlandı.', 'success').then((result)=> {
                  location.reload();
                });
              }).catch(() => {
            Swal.fire({text: store.getters.getPriceListsErrors[0], confirmButtonText: "Tekrar dene!",
              icon: "error", buttonsStyling: false, customClass: {confirmButton: "btn fw-bold btn-light-danger",},
            });
          });
        }
      });

    }

    function click_Open_HotelDelete(prmHotelInfo) {
      console.log("click_Open_HotelDelete START");
      Swal.fire({
        title: 'Emin misiniz?', text: "Kayıt silme işlemi geri alınamaz.",
        icon: "warning", showCancelButton: true,
        confirmButtonText: 'Kaydı Sil', cancelButtonText: `Vazgeç`,
        confirmButtonColor: '#9c1d1d', cancelButtonColor: '#3085d6',
      }).then(function (result) {
        if (result.isConfirmed) {
            store.dispatch(Actions.RESERVATIONS_PRODUCT_DELETE, {ID: prmHotelInfo.ID, ReservationID: prmHotelInfo.ReservationID, ProductType: "HOTEL"})
              .then(() => {
                Swal.fire('Silindi!', 'Kayıt silme işlemi tamamlandı.', 'success').then((result)=> {
                  location.reload();
                });
              }).catch(() => {
            Swal.fire({text: store.getters.getPriceListsErrors[0], confirmButtonText: "Tekrar dene!",
              icon: "error", buttonsStyling: false, customClass: {confirmButton: "btn fw-bold btn-light-danger",},
            });
          });
        }
      });
    }

    function click_PaymentDelete(prmTransferPayment) {
      console.log("click_PaymentDelete START ");
      Swal.fire({
        title: 'Emin misiniz?', text: "Kayıt silme işlemi geri alınamaz.",
        icon: "warning", showCancelButton: true,
        confirmButtonText: 'Kaydı Sil', cancelButtonText: `Vazgeç`,
        confirmButtonColor: '#9c1d1d', cancelButtonColor: '#3085d6',
      }).then(function (result) {
        if (result.isConfirmed) {
          store.dispatch(Actions.RESERVATION_PAYMENT_DELETE, {ID: prmTransferPayment.ID, ReservationID: (theDetail.value as any) .ID, newReservationStatus: false,})
              .then(() => {
                Swal.fire('Silindi!', 'Kayıt silme işlemi tamamlandı.', 'success')
                    .then((result)=>{
                      location.reload();
                    });
              }).catch(() => {
            Swal.fire({text: store.getters.getPriceListsErrors[0], confirmButtonText: "Tekrar dene!",
              icon: "error", buttonsStyling: false, customClass: {confirmButton: "btn fw-bold btn-light-danger",},
            });
          });
        }
      });
    }

    function click_Transfer_Delete(prmTransferInfo) {
      console.log("click_Transfer_Delete START ");
      Swal.fire({
        title: 'Emin misiniz?', text: "Kayıt silme işlemi geri alınamaz.",
        icon: "warning", showCancelButton: true,
        confirmButtonText: 'Kaydı Sil', cancelButtonText: `Vazgeç`,
        confirmButtonColor: '#9c1d1d', cancelButtonColor: '#3085d6',
      }).then(function (result) {
        if (result.isConfirmed) {


          store.dispatch(Actions.RESERVATIONS_PRODUCT_DELETE, {ID: prmTransferInfo.ID, ReservationID: prmTransferInfo.ReservationID, ProductType: "TRANSFER"})
              .then(() => {
                  Swal.fire('Silindi!', 'Kayıt silme işlemi tamamlandı.', 'success')
                    .then((result)=>{
                      location.reload();
                    });
              }).catch(() => {
                Swal.fire({text: store.getters.getPriceListsErrors[0], confirmButtonText: "Tekrar dene!",
                    icon: "error", buttonsStyling: false, customClass: {confirmButton: "btn fw-bold btn-light-danger",},
                });
            });
        }
      });
    }

    function click_Kplus_StartProcess() {
        console.log("click_Kplus_StartProcess START");

        store.dispatch(Actions.RESERVATIONS_KPLUS_PROCESS_START, {ReservationID: paramId.value}).then((prmData) => {
            console.log(prmData);
            console.log(prmData.Result.PrsResult);
            if (prmData.Result.PrsResult==="DONE") {
                //libTools.method.swalSuccess("Tamam", "KPlus Kodu Başarılı Şekilde Gönderildi. Pnr: "+prmData.Result.Value);

                (refKPlusDetailModal.value as any).setResult(prmData.ReservationID, prmData.Token, prmData.Result);

            } else {
                Swal.fire({html:"<b>Sorun Oluştu</b>: <br> "+prmData.Result.Messages[0], confirmButtonText: "Tamam!",
                    icon: "error", buttonsStyling: false, customClass: {confirmButton: "btn fw-bold btn-light-danger",},
                });
            }

        }).catch(() => {
            //
        });

    }

    const dataHotelList = computed(() => {
      let dataComputed = store.getters.hotelListTo;
      return dataComputed;
    });


    function hideAddProductModalButton() {
      const myProductDetail=(theDetail.value as any).ITEMS;
      console.log("lockProductWithInvoiceID the detail " + JSON.stringify(myProductDetail));
      const myAddHotelID = document.getElementById("addNewHotel") as HTMLElement;
      const myAddFlightID = document.getElementById("addNewFlight") as HTMLElement;
      const myAddTransferID = document.getElementById("addNewTransfer") as HTMLElement;
      for (let i=0; i<myProductDetail.length; i++){
        let each = myProductDetail[i];
        if(each.ProductType==="HOTEL" && each.LockProduct ==='1'){
          myAddHotelID.style.setProperty("display", "none", "important");
        }
        if(each.ProductType==="HOTEL" && (each.LockProduct ==='0' || each.LockProduct===null)){
          myAddHotelID.style.setProperty("display", "block", "important");
        }
        if(each.ProductType==="FLIGHT" && each.LockProduct ==='1'){
          myAddFlightID.style.setProperty("display", "none", "important");
        }
        if(each.ProductType==="FLIGHT" && (each.LockProduct ==='0' || each.LockProduct===null)){
          myAddFlightID.style.setProperty("display", "block", "important");
        }
        if(each.ProductType==="TRANSFER" && each.LockProduct ==='1'){
          myAddTransferID.style.setProperty("display", "none", "important");
        }
        if(each.ProductType==="TRANSFER" && (each.LockProduct ==='0' || each.LockProduct===null)){
          myAddTransferID.style.setProperty("display", "block", "important");
        }

      }
    }

    function lockProduct(prmProductID, prmReservationID) {
      const myLockProduct = document.getElementById("productlock" + prmProductID);

      const vvvPayload = {
        ID:prmProductID,
        ReservationID:prmReservationID,
        LockProduct: myLockProduct.getAttribute("value"),
      };

      store.dispatch(Actions.RESERVATIONS_PRODUCT_UPDATE, vvvPayload).then((prmData) => {
        if (prmData.status===200) {
          Swal.fire('Kilitlendi!', 'Kayıt kilitleme işlemi başarıyla tamamlandı.', 'success').then((result)=>{
            location.reload();

          });
        } else {
          libTools.method.swalError("Tamam", "Hata Oluştu");
        }
      }).catch(() => {
        libTools.method.swalError("Tamam", "Hata Oluştu");
      });
      hideAddProductModalButton();
    }


    function unlockProduct(prmProductID, prmReservationID) {

      const myUnLockProduct = document.getElementById("productunlock" + prmProductID);

      const vvvPayload = {
        ID:prmProductID,
        ReservationID:prmReservationID,
        LockProduct: myUnLockProduct.getAttribute("value"),
      };

      store.dispatch(Actions.RESERVATIONS_PRODUCT_UPDATE, vvvPayload).then((prmData) => {
        if (prmData.status===200) {
          Swal.fire('Kilit kaldırıldı!', 'Kayıt kilidi kaldırma işlemi başarıyla tamamlandı.', 'success').then((result)=>{
            location.reload();

          });
        } else {
          libTools.method.swalError("Tamam", "Hata Oluştu");
        }
      }).catch(() => {
        libTools.method.swalError("Tamam", "Hata Oluştu");
      });
      hideAddProductModalButton();
    }


  /*  const payloadInvoice = {
      INVoi:(theDetail.value as any).ITEMS.InvoiceID,
    };
    store.dispatch(Actions.INVOICES_LIST, payloadInvoice);*/

    const resID = id;

    const arrInvoicesList = ref([]);
    const arrInvoicesList2 = ref([]);

    function setInvoiceDetails() {
      if((theDetail.value as any).Invoices!==null){
        for(let i=0; i<JSON.parse((theDetail.value as any).Invoices).length; i++){
          let each = JSON.parse((theDetail.value as any).Invoices)[i];
          if(each!==0){
            const payloadInvoice = {
              "ID":each,
            };

            /*store.dispatch(Actions.INVOICE_ITEMS_LIST, {'InvoiceID': 520});
            const invoiceItemList = computed(() => {
              return  store.getters.invoiceitemsList;
            })*/
            store.dispatch(Actions.INVOICES_LIST, payloadInvoice).then((prmData) => {
              arrInvoicesList.value = prmData.data;
              arrInvoicesList2.value.push(arrInvoicesList.value)
            })
          }

        }

      }


    }

    const itemID = ref<number>(0);
    function clickShowInvoice(ID) {
      itemID.value=ID;

    }





    return {
      dateConvert, libTools,
      paramId,
      theDetail,
      dataReservationsDetails,
      arrPaxes,
      getItems,
      getFirstPax,
      refHotelVoucherModal, refFlightVoucherModal, refTransferVoucherModal, refReservationVoucherModal,
      click_Open_VoucherHotel, click_Open_VoucherFlight, click_Open_VoucherTransfer, click_Open_VoucherReservation,
      click_Kplus_StartProcess,
      refTransferUpdateModal,
      click_Open_TransferUpdate,
      refHotelAddModal, refHotelUpdateModal, refHotelHistoryModal,
      click_Open_HotelAdd, click_Open_HotelUpdate, click_Open_HotelDelete, click_Open_PriceHistoryHotel,
      refFlightUpdateModal,
      click_Open_FlightUpdate,
      click_Open_FlightDelete,
      refPaymentAddModal,
      click_Open_PaymentAdd,
      click_PaymentDelete,
      click_Transfer_Delete,
      refKPlusDetailModal,
      refProductLogMains,
      refRemarks,
      id,
      manuelProductCancel,
      manuelProductReCallCancel,
      manuelProductConfirm,
      reservationConfirmTypes,
      dataHotelList,
      lockProduct,
      unlockProduct,
      resID,
      itemID,
      arrInvoicesList2,
      clickShowInvoice,
      paymentMethods,

      click_Open_LogMains,
    };
  },


});
