
import {computed, defineComponent, ref,} from "vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {libTools} from "@/core/appcore/libs/Lib_Tools";

export default defineComponent({
    name: "reservation-product-log-mains",
    components: {
    },

    methods: {
      setContent(prmProductInfo) {
        this.getProductInner(prmProductInfo);
      },
    },


    setup() {

      const arrLogMainsList = ref([])

      const arrProductInfo = ref({});

      function getProductInner(prmProductInfo) {

        (arrProductInfo.value)=(prmProductInfo);
        const payloadlogmains = {
          "TableName":"ReservationProducts",
          "TableID":(arrProductInfo.value as any).ID,
          //"TableID":"43541",
          // "TableName":"Reservations",
          // "TableID":"29887",
        };

        store.dispatch(Actions.LOG_MAINS_LIST, payloadlogmains).then((prmData) => {
          arrLogMainsList.value=prmData;
        })
      }



      const mylogmains = computed(() => {
        return store.getters.logmainsList;
      });


        return {
          libTools,
          mylogmains,
          arrLogMainsList,
          getProductInner,
          arrProductInfo,
        };
    }


});

